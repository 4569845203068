<template>
  <div class="page-item-wrap">
    <main class="sub __login">
      <div class="inner __login-wrap">
        <div class="tit req-type">
          <h1>회원가입</h1>
          <p class="stit">회원가입이 완료 되었습니다.</p>
        </div>
        <div class="sub-cont result">
          <div class="result-box">
            <p class="result-txt">
              <!--회원님의 아이디는 <strong>calebk**</strong> 입니다.-->
              <strong>회원가입 경로 : {{ pass }}</strong
              ><br />
              <strong>{{ passName }} </strong>님, 호구153 회원가입을
              축하드립니다.
            </p>
            <div v-if="!isApp">
              <div v-if="isPc" class="appLinkBox">
                <button
                  type="button"
                  class="appLinkBtn"
                  @click="appDown('android')"
                >
                  안드로이드 앱 설치
                </button>
                <button
                  type="button"
                  class="appLinkBtn"
                  @click="appDown('IOS')"
                >
                  IOS 앱 설치
                </button>
              </div>
              <div v-if="!isPc" class="appLinkBox">
                <button
                  type="button"
                  class="appLinkBtn"
                  @click="appDown()"
                  style="width: 50%"
                >
                  앱 다운로드
                </button>
              </div>
            </div>
          </div>
          <ul class="link-list" v-if="isEmployer">
            <li><router-link to="/">홈으로 이동</router-link></li>
            <li><router-link to="/regist-job">일자리 등록</router-link></li>
            <li><router-link to="/account">기업정보 등록</router-link></li>
            <li><router-link to="">앱 다운로드</router-link></li>
          </ul>
          <ul class="link-list" v-else>
            <li><router-link to="/find-id">이력서 등록</router-link></li>
            <li>
              <router-link to="/smart-match">스마트 매칭등록</router-link>
            </li>
            <li>
              <router-link to="/find-job">일자리 찾기</router-link>
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { authCheck } from 'Utils/auth'

export default {
  props: {
    pass: {
      type: String,
    },
    passId: {
      type: String,
    },
    passName: {
      type: String,
    },
    passToken: {
      type: String,
    },
    passLoginType: {
      type: String,
    },
  },
  data() {
    return {
      isPc: false,
      AppUrl: null,
      iosUrl: 'https://url.kr/7ukosm',
      androidUrl: 'https://url.kr/dj6zeq',
    }
  },
  async created() {
    const res = await authCheck()
    if (res) {
      this.$store.commit('SET_IS_LOGIN', true)
      this.$store.commit('SET_ACCOUNT_INFO', res)
    }
    if (!this.isApp && this.checkMobile == 'true,IOS') {
      this.AppUrl = this.iosUrl
    } else if (!this.isApp && this.checkMobile == 'false,android') {
      this.AppUrl = this.androidUrl
    } else {
      this.isPc = true
    }
  },
  methods: {
    appDown(app) {
      if (app == 'android') {
        this.AppUrl = this.androidUrl
      } else if (app == 'IOS') {
        this.AppUrl = this.iosUrl
      }
      location.href = this.AppUrl
    },
  },
  computed: {
    checkMobile() {
      var varUA = navigator.userAgent.toLowerCase() //userAgent 값 얻기
      var mobile = /iphone|ipad|ipod|android/i.test(
        navigator.userAgent.toLowerCase(),
      )
      //console.log(mobile)
      if (mobile && varUA.indexOf('android') > -1) {
        //모바일 처리
        //alert('모바일')
        // alert('안드로이드')
        return [false, 'android']
      } else if (
        mobile &&
        (varUA.indexOf('iphone') > -1 ||
          varUA.indexOf('ipad') > -1 ||
          varUA.indexOf('ipod') > -1)
      ) {
        //alert('아이폰')
        return [true, 'IOS']
      } else {
        //alert('아이폰')
        //아이폰, 안드로이드 외
        return [false, 'PC']
      }
    },
  },
}
</script>

<style></style>
